import { useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import { ConfigContext } from "../../assets/contexts/configContext";
import { Link, NavLink } from "react-router-dom";
import {
  CART_MAIN_LINK,
  CATALOG_MAIN_LINK,
  PHONE,
} from "../../assets/utils/constants";
import { CartIcon, CloseIcon, MenuIcon, SearchIcon } from "../../assets/icons/icons";
import mainApi from "../../assets/api/MainApi";
import CatalogDrop from "./CatalogDrop/CatalogDrop";
import MenuPopup from "./MenuPopup/MenuPopup";
import { CartContext } from "../../assets/contexts/cartContext";
import useScrollDirection from "../../assets/hooks/useScrollDirection";
import { getURLRelativePath, isExternalURL } from "../../assets/utils/utils";
import Search from "../Search/Search";
import useWindowSize from "../../assets/hooks/useWindowSize";
import logo from "../../assets/images/logo.webp";
import { AboutBannerLogo } from "../../assets/icons/custom";

function Header() {
  const scrollDirection = useScrollDirection();
  const { width } = useWindowSize();
  const [searchOpen, setSearchOpen] = useState(false)

  const config = useContext(ConfigContext);
  const cart_context = useContext(CartContext)
  const {
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    setCart,
  } = cart_context
  const { initialConstants } = config
  const { FIRST_LEVEL_CATEGORIES, HEADER_LINKS } = initialConstants

  const catalogBtnRef = useRef(null);
  const linksRef = useRef(null);

  const [isCatalogOpen, setCatalogOpen] = useState(false);
  // const [categories, setCategories] = useState(FIRST_LEVEL_CATEGORIES && FIRST_LEVEL_CATEGORIES.length > 0 && Array.isArray(FIRST_LEVEL_CATEGORIES) ? FIRST_LEVEL_CATEGORIES : []);
  const [topCategories, setTopCategories] = useState(undefined);

  // useEffect(() => {
  //   mainApi
  //     .getCategories({
  //       limit: 25,
  //       shop_id: "65ae789bcb5f6a9f66e457aa",
  //     })
  //     .then((res) => {
  //       console.log(res.data);
  //       setCategories(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const [isMenuOpen, setMenuOpen] = useState(false);

  const [scrollWindowPosition, setScrollWindowPosition] = useState(0);
  const [popupScrollPosition, setPopupScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollWindowPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.top = `-${scrollWindowPosition}px`;
      setPopupScrollPosition(scrollWindowPosition);
      setTimeout(() => {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        document.body.style.height = "100%";
      }, 10);

      console.log(window.pageYOffset);
    } else {
      document.body.style.overflow = "unset";
      document.body.style.position = "unset";
      document.body.style.width = "unset";
      document.body.style.height = "unset";
      document.body.style.top = `unset`;
      console.log(popupScrollPosition);
      window.scrollTo(0, popupScrollPosition);
      setScrollWindowPosition(popupScrollPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const [visibleCategories, setVisibleCategories] = useState([]);

  useEffect(() => {
    if (!HEADER_LINKS || HEADER_LINKS.length === 0) return
    const updateVisibleLinks = () => {
      if (linksRef.current) {
        const maxAllowedWidth = linksRef.current.offsetWidth;
        if (maxAllowedWidth <= 0) return
        let totalWidth = 0;
        const linksToShow = [];

        HEADER_LINKS.forEach((link, index) => {
          const linkElement = document.createElement('div');
          linkElement.className = 'link';
          linkElement.style.visibility = 'hidden';
          linkElement.style.position = 'absolute';
          linkElement.innerText = link.name;
          document.body.appendChild(linkElement);
          const linkWidth = linkElement.offsetWidth + 28 * 2;
          document.body.removeChild(linkElement);

          if (totalWidth + linkWidth <= maxAllowedWidth) {
            linksToShow.push(link);
            totalWidth += linkWidth;
          }
        });

        setVisibleCategories(linksToShow);
      }
    };

    updateVisibleLinks();
    window.addEventListener('resize', updateVisibleLinks);

    return () => window.removeEventListener('resize', updateVisibleLinks);
  }, [HEADER_LINKS]);

  return (
    <>

      <header className={`header  ${scrollDirection === "down" ? searchOpen ? "header_show" : "header_hide" : "header_show"}`}>
        <CatalogDrop
          isOpened={isCatalogOpen}
          setOpened={setCatalogOpen}
          categories={FIRST_LEVEL_CATEGORIES}
          catalogBtnRef={catalogBtnRef}
        />
        <MenuPopup
          isOpened={isMenuOpen}
          setOpened={setMenuOpen}
          categories={FIRST_LEVEL_CATEGORIES}
        />
        <div className="header__content">
          <Link className="header__logo" to={"/"}>
            <AboutBannerLogo mainClassName={'header__logo-img'} fillClassName={'header__logo-img-fill'} />
          </Link>
          <div className="header__catalog-with-links">
            <div className="header__catalog">
              {/* <div className='header__categories'>
                        {categories.map((category, i) => (
                            <Link className='header__category' to={`/${CATALOG_MAIN_LINK}/${category.translit_name}`}>
                                {category.title}
                            </Link>
                        ))}
                    </div> */}
              {/* <button className='header__catalog-btn' type='button'>
                        <h3 className='header__catalog-btn-text'>Каталог товаров</h3>
                    </button> */}

              <button
                className="header__catalog-btn"
                type="button"
                ref={catalogBtnRef}
              >
                <h3 className="header__catalog-btn-text">Каталог</h3>
                <svg className={`header__catalog-btn-arrow ${isCatalogOpen ? 'header__catalog-btn-arrow_active' : ''}`} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="10" height="10" fill="white" />
                  <path d="M2 3L4.85858 5.85858C4.93668 5.93668 5.06332 5.93668 5.14142 5.85858L8 3" stroke="#2C314F" stroke-width="1.2" stroke-linecap="round" />
                </svg>

              </button>
            </div>
            <div className="search_pc">
              <button className="header__input-icon" onClick={() => setSearchOpen(true)} type="button">
                <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
              </button>
              <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={linksRef && linksRef.current ? linksRef.current.offsetWidth + 28 + 20 : '700px'} />
            </div>

            {HEADER_LINKS && HEADER_LINKS.length > 0 ?
              <div className="header__links" ref={linksRef}>
                {visibleCategories.map((link) => {
                  if (isExternalURL(link.url)) {
                    return (
                      <Link
                        key={link._id}
                        className={`header__link`}
                        to={link.url}
                        target={'_blank'} // Conditionally set target attribute
                        rel={'noopener noreferrer'} // Add rel for security if external
                      >
                        {link.name}
                      </Link>
                    )
                  } else {
                    const relativePath = getURLRelativePath(link.url);
                    return (
                      <NavLink
                        key={link._id}
                        className={({ isActive }) => {
                          return `header__link ${isActive ? 'header__link_active' : ''}`
                        }}
                        to={relativePath}
                      >
                        {link.name}
                      </NavLink>
                    )
                  }
                })}
              </div>
              : null}
          </div>

          <div className="header__cart-and-menu">
            <button className="header__search-icon-mobile" onClick={() => setSearchOpen(true)} type="button">
              <SearchIcon mainClassName={'header__input-icon'} fillClassName={'header__input-icon-fill'} />
            </button>

            <div className="header__phone-box">
              <p className="header__phone-title">Консультант LeffLine</p>
              <a className="header__phone" href={`tel:${PHONE.value}`}>{PHONE.formated}</a>
            </div>

            <div className="header__cart-box">
              {cart && cart.length > 0 ? (
                <p className="header__cart-count">{cart.length}</p>
              ) : null}
              <Link className="header__cart" to={`/${CART_MAIN_LINK}`}>
                <CartIcon
                  mainClassName={"header__cart-icon"}
                  fillClassName={"header__cart-icon-stroke"}
                />
              </Link>
            </div>
            <button
              className="header__menu"
              type="button"
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <MenuIcon
                mainClassName={"header__menu-icon"}
                fillClassName={"header__menu-icon-fill"}
              />
            </button>
          </div>

          <div className="search_mobile">

            <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} maxWidth={`100%`} />
          </div>

        </div>
      </header>
    </>
  );
}

export default Header;
